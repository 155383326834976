import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';

// @ts-ignore
import lineTypeIcon from '@atom/components/common/svgIcons/lineTypeIcon.svg';
import { Icon, ListTable, Menu, Progress } from '@atom/mui';
import { Schema } from '@atom/types/schema';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

const { TableRow, TableCell, TableHead } = ListTable;
const { MenuItem } = Menu;

const styles = {
  tableContainer: {
    height: `calc(100%)`,
  },
  progress: {
    height: '100%',
  },
  link: {
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
  },
  optionsMenuIcon: {
    marginRight: '1rem',
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
  },
  emptyContent: {
    marginLeft: '0.5rem',
  },
};

interface Props {
  schemas: Partial<Schema>[];
  isTableLoading: boolean;
  handleDuplicateSchema: (schemaId: string) => void;
  setSchemaToDelete: Dispatch<SetStateAction<Partial<Schema>>>;
}

const SchemaPortalTable = ({
  schemas,
  isTableLoading,
  handleDuplicateSchema,
  setSchemaToDelete,
}: Props) => {
  if (isTableLoading) {
    return <Progress style={styles.progress} />;
  }

  const locationTypeDisplay = (locationType: string): ReactNode => {
    if (locationType === 'Point') {
      return (
        <div style={styles.iconTextContainer}>
          <Icon>location_on</Icon>
          Point
        </div>
      );
    } else if (locationType === 'LineString') {
      return (
        <div style={styles.iconTextContainer}>
          <img src={lineTypeIcon} />
          Line
        </div>
      );
    }
    return <div style={styles.emptyContent}>-</div>;
  };

  // Documentation: https://virtuoso.dev/mui-table-virtual-scroll/
  // Using a combination of vanilla MUI and Atom components for styling
  const TableComponents = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: props => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: props => <TableHead {...props} />,
    TableRow: props => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  return (
    <TableVirtuoso
      style={styles.tableContainer}
      data={schemas}
      components={TableComponents}
      fixedHeaderContent={() => (
        <TableRow header>
          <TableCell variant="head">Name</TableCell>
          <TableCell variant="head" style={{ width: '7rem' }}>
            Status
          </TableCell>
          <TableCell variant="head" style={{ width: '5rem' }}>
            Location Type
          </TableCell>
          <TableCell variant="head" style={{ width: '12rem' }}>
            Created By
          </TableCell>
          <TableCell variant="head" style={{ width: '6rem' }}>
            Created On
          </TableCell>
          <TableCell variant="head" style={{ width: '12rem' }}>
            Modified By
          </TableCell>
          <TableCell variant="head" style={{ width: '6rem' }}>
            Modified On
          </TableCell>
          <TableCell variant="head" style={{ width: '3rem' }}>
            {/* Blank for the additional options menu */}
          </TableCell>
        </TableRow>
      )}
      itemContent={(index, schema) => (
        <>
          <TableCell>
            <Link style={styles.link} to={`inventoryTypes/${schema.id}`}>
              {schemaUtilities.getSchemaIconFromSchemaOrAsset(schema)}
              {schema.name}
            </Link>
          </TableCell>
          <TableCell>
            <div style={styles.iconTextContainer}>
              <Icon>{schema.isPublished ? 'lock' : 'edit'}</Icon>
              {schema.isPublished ? 'Published' : 'Draft'}
            </div>
          </TableCell>
          <TableCell>{locationTypeDisplay(schema.locationType)}</TableCell>
          <TableCell>
            {schema.createdBy?.firstName} {schema.createdBy?.lastName}
          </TableCell>
          <TableCell>{setDisplayDate(schema.createdDate)}</TableCell>
          <TableCell>
            {schema.updatedBy?.firstName} {schema.updatedBy?.lastName}
          </TableCell>
          <TableCell>{setDisplayDate(schema.updatedDate)}</TableCell>
          <TableCell>
            {(schema.isPublished || hasRolePermissions(ROLE_SETS.ADMIN)) && (
              <Menu IconButtonProps={{ style: styles.optionsMenuIcon }}>
                {schema.isPublished && (
                  <MenuItem
                    startAdornment={<Icon>content_copy</Icon>}
                    onClick={() => handleDuplicateSchema(schema.id)}
                  >
                    Duplicate
                  </MenuItem>
                )}
                {hasRolePermissions(ROLE_SETS.ADMIN) && (
                  <MenuItem
                    startAdornment={<Icon>delete</Icon>}
                    onClick={() => setSchemaToDelete(schema)}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </TableCell>
        </>
      )}
    />
  );
};

export default SchemaPortalTable;
